







































































































































































































import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'

import { getElasticSearchClient } from '@/services/elasticSearch'
import DatePicker from '@/components/reports/DatePicker.vue'
import GroupByIntervalSelect from '@/components/reports/GroupByIntervalSelect.vue'
import ManageFilters from '@/components/reports/ManageFilters.vue'
import { CurrencyEnum } from '../../../../shared/types/types'
import { formatPrice } from '../../lib/utils'
import DatePickerMixin from '../../mixins/DatePicker.mixin'
import { AndFilterGroup, Filter, IntervalEnum } from '../../lib/reports/types'
import {
  elasticSearchFilterQuery,
  filterPriceLabel,
  filterStartCase,
  filterValueLabel,
  totalSalesBackgroundOpacity,
} from '../../lib/reports/functions'
import CurrencySelect from '../../components/shared/inputs/CurrencySelect.vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

interface Aggr {
  key: string
  price: { value: number }
  quantity: { value: number }
  sumRevenue: { value: number }
  sumNet: { value: number }
  netSales: { value: number }
  productTitle: any
}

export default DatePickerMixin.extend({
  name: 'SalesByProductPrices',
  components: {
    Bar,
    DatePicker,
    GroupByIntervalSelect,
    CurrencySelect,
  },
  filters: {
    filterPriceLabel,
    filterStartCase,
    filterValueLabel,
  },
  data() {
    return {
      currency: CurrencyEnum.Usd as CurrencyEnum,
      switch: false,
      timeInterval: IntervalEnum.Day,
      dataFilters: [
        // {
        //   orGroups: [
        //     { filter: 'ordersPaymentStatus', operator: 'is', values: ['paid'] },
        //     {
        //       filter: 'ordersPaymentStatus',
        //       operator: 'is',
        //       values: ['partially_refunded'],
        //     },
        //     {
        //       filter: 'ordersPaymentStatus',
        //       operator: 'is',
        //       values: ['refunded'],
        //     },
        //   ],
        // },
      ] as AndFilterGroup[],
      data: null as Record<string, any> | null,
      visibleColumns: [
        'key',
        'price',
        'count',
        'revenue',
        // 'discounts',
        'net',
        // 'tax',
        'netSales',
      ] as string[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const fieldPrefix = this.currency === CurrencyEnum.Usd ? 'usdPrices.' : ''
      return {
        aggs: {
          products: {
            terms: {
              field: 'productId',
              size: 10000,
            },
            aggs: {
              prices: {
                terms: {
                  field: `${fieldPrefix}originalPrice.amount`,
                  size: 1000,
                  order: {
                    _key: 'desc',
                  },
                },
                aggs: {
                  productName: {
                    terms: {
                      field: 'productTitle.keyword',
                    },
                  },
                },
              },
            },
          },
        },
        size: 0,
        query: elasticSearchFilterQuery(
          this.dataFilters,
          this.datePicker,
          false,
          this.currency === CurrencyEnum.Usd
        ),
      }
    },
    rows(): Aggr[] {
      const productBuckets = this.data?.aggregations.products.buckets ?? []
      const rows: Aggr[] = []

      for (const productBucket of productBuckets) {
        const pricesBuckets = productBucket.prices.buckets ?? []
        for (const priceBucket of pricesBuckets) {
          const productNameBuckets = priceBucket.productName.buckets ?? []
          for (const productNameBucket of productNameBuckets) {
            const productName = priceBucket.productName.buckets[0].key
            if (!productName) {
              continue
            }
            const topush = {
              key: productName,
              price: { value: priceBucket.key },
              quantity: { value: priceBucket.doc_count },
              sumRevenue: { value: priceBucket.doc_count * priceBucket.key },
              sumNet: { value: priceBucket.doc_count * priceBucket.key },
              netSales: { value: priceBucket.doc_count * priceBucket.key },
              productTitle: productNameBucket.key,
            }
            rows.push(topush)
          }
        }
      }

      return rows
    },
    sum(): Aggr {
      const sum = this.rows.reduce(
        (acc: Aggr, b: Aggr) => {
          acc.quantity.value = acc.quantity.value + b.quantity.value
          acc.sumRevenue.value = acc.sumRevenue.value + b.sumRevenue.value
          acc.netSales.value = acc.netSales.value + b.netSales.value
          acc.sumNet.value = acc.sumNet.value + b.sumNet.value
          acc.price.value = acc.price.value + b.price.value

          return acc
        },
        {
          quantity: { value: 0 },
          sumRevenue: { value: 0 },
          netSales: { value: 0 },
          price: { value: 0 },
          sumNet: { value: 0 },
        } as Aggr
      )
      // average price
      sum.price.value = sum.price.value / this.rows.length
      return sum
    },
    totalSalesBackgroundOpacity(): Record<number, number> {
      return totalSalesBackgroundOpacity<Aggr>(this.rows, this.netSales)
    },
  },
  watch: {
    datePicker: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    timeInterval(): void {
      this.fetchData()
    },
    dataFilters: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    currency: {
      immediate: true,
      handler(currency): void {
        if (currency === CurrencyEnum.Usd) {
          let hasTotalPriceCurrency = false
          for (const andGroup of this.dataFilters) {
            for (const orGroup of andGroup.orGroups) {
              if (orGroup.filter === 'totalPrice.currency') {
                hasTotalPriceCurrency = true
              }
            }
          }
          if (hasTotalPriceCurrency) {
            this.dataFilters = this.dataFilters.filter((andGroup) => {
              return !andGroup.orGroups.some(
                (orGroup) => orGroup.filter === 'totalPrice.currency'
              )
            })
          }
        } else {
          let hasTotalPriceCurrency = false
          for (const andGroup of this.dataFilters) {
            for (const orGroup of andGroup.orGroups) {
              if (orGroup.filter === 'totalPrice.currency') {
                hasTotalPriceCurrency = true
                orGroup.values = [currency]
              }
            }
          }
          if (!hasTotalPriceCurrency) {
            this.dataFilters.push({
              orGroups: [
                {
                  filter: 'totalPrice.currency',
                  operator: 'is',
                  values: [currency],
                },
              ],
            })
          }
        }
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    formatPrice(price: number): string {
      return formatPrice({ amount: price, currency: CurrencyEnum.Usd })
    },
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/order_line_items/_search',
        this.query
      )
      this.data = data
    },
    onRangeChanged(range: { startDate: Date; endDate: Date }): void {
      this.datePicker.startDate = range.startDate
      this.datePicker.endDate = range.endDate
    },
    openManageFilters(): void {
      this.$buefy.modal.open({
        parent: this,
        customClass: 'manage-filters',
        scroll: 'keep',
        component: ManageFilters,
        hasModalCard: true,
        props: {
          initFilters: this.dataFilters,
        },
        trapFocus: true,
        events: {
          value: (value: AndFilterGroup[]): void => {
            this.dataFilters = JSON.parse(
              JSON.stringify(value)
            ) as AndFilterGroup[]
          },
        },
      })
    },
    removeAndGroup(group: AndFilterGroup) {
      this.dataFilters.splice(this.dataFilters.indexOf(group), 1)
    },
    removeOrGroup(andGroup: AndFilterGroup, orGroup: Filter) {
      if (andGroup.orGroups.length === 1) {
        // If there are more and groups, but there is only one orGroup inside then remove the andGroup
        this.removeAndGroup(andGroup)
      } else {
        andGroup.orGroups.splice(andGroup.orGroups.indexOf(orGroup), 1)
      }
    },
    netSales(row: Aggr) {
      return row.sumRevenue.value
    },
  },
})
